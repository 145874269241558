import React from "react"
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaFacebookSquare className="social-icon" />,
    url: "#",
  },
  {
    id: 2,
    icon: <FaInstagramSquare className="social-icon" />,
    url: "#",
  },
]
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link">
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
