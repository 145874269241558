import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../images/logo-white.png"
import logoBK from "../images/logo-black.png"
import { FiMenu } from "react-icons/fi"
import PageLinks from "../constants/navLinks"

const Navbar = ({ toggleSidebar, home }) => {
  return (
    <Wrapper className={`${home ? "navbar--home" : ""}`}>
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img
              src={home ? logo : logoBK}
              className="logo"
              alt="fire skilled logo"
            />
          </Link>
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <FiMenu />
          </button>
        </div>
        <PageLinks styleClass="nav-links" />
      </div>
    </Wrapper>
  )
}

export default Navbar

const Wrapper = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  z-index: 200;
  background: var(--bg-2);
  box-shadow: 0 0 15px rgb(0 0 50 / 9%);

  .logo {
    width: 200px;
    margin-top: 5px;
  }

  &.navbar--home {
    background: transparent;
  }
  .nav-center {
    width: 90vw;
    max-width: 1170px;
    margin: 0 auto;
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .toggle-btn {
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--color-primary-1);
    cursor: pointer;
    transition: var(--transition);
    margin-top: 10px;
  }
  .toggle-btn:hover {
    color: var(--color-motif-2);
  }
  &.navbar--home .toggle-btn {
    color: var(--bg-4);
  }
  .nav-links {
    display: none;
  }
  @media screen and (min-width: 768px) {
    & {
      background: var(--bg-1);
    }
    .toggle-btn {
      display: none;
    }
    .nav-links {
      display: flex;
      justify-content: flex-end;
    }
    .nav-links li {
      margin-right: 2rem;
    }
    .nav-links a {
      text-transform: capitalize;
      color: var(--color-primary-1);
      font-weight: 600;
      letter-spacing: var(--spacing);
      transition: var(--transition);
      padding: 0.5rem 0;
    }
    .nav-links a:hover {
      color: var(--color-motif-1);
      box-shadow: 0px 2px var(--color-motif-1);
    }
    &.navbar--home .nav-links a {
      text-transform: capitalize;
      color: var(--color-primary-4);
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
    }
  }
`
