import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SocialLinks from "../constants/socialLinks"

const Footer = () => {
  return (
    <Wrapper>
      <div>
        <SocialLinks styleClass="footer-links"></SocialLinks>
        <p className="footer-company">
          <strong>Fire Skilled Ltd London</strong>
          <a className="link link-dotted" href="tel:00447912091204">
            +44 7912 091 204
          </a>
          <a className="link link-dotted" href="mailto:info@fireskilled.co.uk">
            info@fireskilled.co.uk
          </a>
        </p>
        <p className="footer-copy">
          copyright&copy;{new Date().getFullYear()} Fire Skilled Ltd All rights
          reserved&nbsp;|&nbsp;
          <Link to="/privacy-policy" className="link">
            Privacy policy
          </Link>
        </p>
      </div>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.footer`
  height: 9rem;
  background: var(--bg-3); /*2f2f36 */
  text-align: center;
  display: grid;
  place-items: center;
  padding: 0.75rem 1rem;

  .footer-company {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: var(--color-primary-4);
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05rem;

    strong {
      font-weight: normal;
      display: inline-block;
    }
  }
  .footer-copy {
    margin-top: 0.5rem;
    color: var(--color-primary-4);
    font-weight: normal;
    text-transform: uppercase;
    font-size: 0.5rem;
    letter-spacing: 0.05rem;
  }
  .link {
    color: var(--color-primary-4);
    transition: var(--transition);
    white-space: nowrap;

    &-dotted {
      margin-left: 0.5rem;

      &:before {
        content: "·";
        padding-right: 0.5rem;
      }

      @media (max-width: 400px) {
        &:first-of-type {
          margin-left: 0;
          &:before {
            content: "";
          }
        }
      }
    }
  }
  .link:hover {
    color: var(--color-motif-1);
  }
  .footer-links {
    margin: 0 auto 0.5rem auto;
  }
  .social-link {
    color: var(--color-primary-4);
  }
  .social-link:hover {
    color: var(--color-motif-1);
  }
`
