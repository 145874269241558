import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

const CookiesBar = () => {
  return (
    <CookieConsent
      buttonStyle={{
        borderRadius: "var(--radius)",
        fontSize: "13px",
      }}
    >
      <div
        style={{
          fontSize: "0.8rem",
          color: "var(--bg-4)",
        }}
      >
        <span>This website use cookies to enhance the user experience. </span>
        <span>
          Read more in our
          <Link
            to="/privacy-policy"
            style={{
              color: "var(--bg-4)",
              textDecoration: "underline",
              marginLeft: "0.25rem",
            }}
          >
            privacy policy
          </Link>
        </span>
      </div>
    </CookieConsent>
  )
}

export default CookiesBar
