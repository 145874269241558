import React from "react"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import CookiesBar from "./CookiesBar"

const Layout = ({ children, home }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} home={home} />
      {children}
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <Footer />
      <CookiesBar />
    </>
  )
}

export default Layout
