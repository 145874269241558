import React from "react"
import { Link } from "gatsby"
const links = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about",
    url: "/about/",
  },
  {
    id: 3,
    text: "services",
    url: "/services/",
  },
  {
    id: 4,
    text: "gallery",
    url: "/gallery/",
  },
  {
    id: 5,
    text: "contact",
    url: "/contact/",
  },
]

export default ({ styleClass }) => {
  return (
    <ul className={`page-links ${styleClass ? styleClass : ""}`}>
      {links.map(link => (
        <li key={link.id}>
          <Link to={link.url}>{link.text}</Link>
        </li>
      ))}
    </ul>
  )
}
